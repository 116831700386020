import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ChildCaseModel} from '../../model/child-case.model';
import {TranslocoService} from '@ngneat/transloco';
import {MuacUtil} from '../../util/muac.util';
import {Router} from '@angular/router';
import algoliasearch from 'algoliasearch';
import {AlgoliaUtil} from '../../util/algolia.util';
import {LoginService} from '../../services/login.service';
import {AlgoliaService} from '../../services/algolia.service';
import {ChildCaseService} from '../../services/child-case.service';
import {FilterChildService} from '../../services/filter-child.service';
import {DateUtil} from '../../util/date.util';
import {User} from '../../model/user.model';
import {MalnutritionLevelUtil} from '../../util/malnutrition-level.util';
import {NextVisitPeriod} from '../../constants/next-visit-period';
import {ChildrenCsvVisitsContentBuilder} from '../../builder/export/csv/children-csv-visits-content-builder';
import {SymptomsService} from '../../services/symptoms.service';
import {ChildrenCsvContentBuilder} from '../../builder/export/csv/children-csv-content-builder';

export declare interface Searchable {
    search(clear: boolean);

    getFilters(): Array<string>;

}

@Component({
    selector: 'app-children',
    templateUrl: './children.component.html',
    styleUrls: ['./children.component.scss']
})
export class ChildrenComponent implements OnInit, OnDestroy, Searchable {

    order = 0;
    orders = {0: 'Próxima visita', 1: 'Nombre', 2: 'MUAC'};

    indexByName;
    indexByVisitDate;
    indexByMuac;
    indexCasesVisits;

    index;
    searchTerm = '';
    results: ChildCaseModel[] = new Array();
    searching = false;
    totalResults = 0;
    totalCases = 0;
    offlineImg = '../../assets/icon/icon-offline.svg';
    profileFilter = '';
    currPage = 0;
    numberOfPages = 0;
    hitsPerPage = 15;

    exporting = false;
    exportingProgress = 0;
    nextVisitPeriods = NextVisitPeriod.values();
    nextVisitStartDate: Date;
    nextVisitEndDate: Date;

    selectedPeriod: string;

    @Input()
    public user: User;
    customPeriodSearch = false;

    constructor(private algoliaService: AlgoliaService,
                private loginService: LoginService,
                private childCaseService: ChildCaseService,
                private filterChildService: FilterChildService,
                private router: Router,
                private translocoService: TranslocoService,
                private symptomService: SymptomsService) {
        const client = algoliasearch(AlgoliaUtil.APP_ID, AlgoliaUtil.getSearchApiKey());
        this.indexByName = client.initIndex('cases_order_by_name');
        this.indexByMuac = client.initIndex('cases_muac');
        this.indexCasesVisits = client.initIndex('cases_visits');
        this.indexByVisitDate = client.initIndex(AlgoliaUtil.getChildrenIndex());
        this.childCaseService.getNextSevenDaysCases().then(result => {
            console.log(result);
        });
        filterChildService.setSearchable = this;
    }

    ngOnInit(): void {
        this.algoliaService.getFilterByProfile(this.loginService.authUser).then(filter => {
            this.profileFilter = filter;
            this.getTotalCases();
            this.search(true);
        });
    }

    ngOnDestroy() {
    }

    isSupervisor() {
        return this.loginService.getCurrentUser().isSupervisor;
    }

    setOrder(order) {
        this.order = parseInt(order);
        this.search(true);
    }

    muac(value: number) {
        return MuacUtil.getMuacClassification(value);
    }

    showChildProfile(childCase: ChildCaseModel) {
        this.router.navigate(['app/child-profile/', childCase.token]);
    }

    getIndex() {
        switch (this.order) {
            case 0:
                return this.indexByVisitDate;
            case 1:
                return this.indexByName;
            case 2:
                return this.indexByMuac;
            default:
                return this.indexByVisitDate;
        }
    }

    getBasicFilter() {
        let basicFilter = '';
        if (this.user != null) {
            basicFilter = 'assistantUid:' + this.user.uid;
        } else {
            basicFilter = this.profileFilter;
        }

        return basicFilter;
    }

    getFilters(): Array<string> {
        let filters = new Array();

        filters = [this.getBasicFilter()];

        if (this.filterChildService.provincesSelected.length > 0) {
            filters = [...filters, this.filterChildService.provincesSelected.map(p => (`province:${p}`))];
        }

        if (this.filterChildService.countysSelected.length > 0) {
            filters = [...filters, this.filterChildService.countysSelected.map(p => (`county:${p}`))];
        }

        if (this.filterChildService.organizationSelected.length > 0) {
            filters = [...filters, this.filterChildService.organizationSelected.map(p => (`organizationName:${p}`))];
        }

        if (this.filterChildService.filtersSelected.length > 0) {
            let filtersOrs = [];
            this.filterChildService.filtersSelected.forEach(filterInt => {
                const filterObj = this.filterChildService.filterOptions.find(f => f.id == filterInt);
                if (filterInt < 4) {
                    if (filterInt == 3) {
                        filtersOrs.push('transferredCase:true');
                    } else {
                        filtersOrs.push('alert.class:' + filterObj.class);
                    }
                } else {
                    filtersOrs.push('marker:' + filterObj.class);
                }
            });
            filters = [...filters, filtersOrs];
        }

        return filters;
    }

    exportList() {
        this.exporting = true;
        this.convertToCsv().then(csvData => {
            this.exporting = false;
            const blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
            const dwldLink = document.createElement('a');
            const url = URL.createObjectURL(blob);
            const isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
            if (isSafariBrowser) {
                dwldLink.setAttribute('target', '_blank');
            }
            dwldLink.setAttribute('href', url);
            dwldLink.setAttribute('download', 'ninos-' + DateUtil.nowFormated('YYYY-MM-DD') + '.csv');
            dwldLink.style.visibility = 'hidden';
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
        }).catch(ex => {
            this.exporting = false;
        });
    }

    async convertToCsv(): Promise<String> {
        const ref = this;
        const titles = await this.translocoService.translateObject('children.export.titles');
        const headers = `${titles}\r\n`;
        const children = await this.algoliaService.getAllResults(
            this.getIndex(),
            this.searchTerm,
            this.getSearchConfigExport(),
            (progress) => {
                ref.exportingProgress = Math.floor(progress * 100) / 100;
            }
        );
        const data = children.flatMap(data => data.hits.map(value => ({...value})));
        const contentBuilder = new ChildrenCsvContentBuilder(data, this.translocoService);
        const csvContent = contentBuilder.build();
        return headers + csvContent;
    }

    getSearchConfig() {
        const facetFilters = this.getFilters();
        let filters = this.getMalnutritionFilters();
        if (filters.length == 0) {
            filters = this.getNextVisitPeriodFilter();
        } else if (NextVisitPeriod.ALL.key != this.selectedPeriod) {
            filters = filters.concat(' AND ' + this.getNextVisitPeriodFilter());
        }

        return {
            filters: filters,
            facetFilters: facetFilters,
            hitsPerPage: this.hitsPerPage,
            page: this.currPage
        };
    }

    getMalnutritionFilters() {
        let filters = '';
        if (this.filterChildService.onlyMalnutritionCases) {
            const levels = MalnutritionLevelUtil.manutritionLevels().map(level => level.description);
            for (let i = 0; i < levels.length; i++) {
                filters = filters.concat(`visits.marker:${levels[i]}`);
                if (i < (levels.length - 1)) {
                    filters = filters.concat(' OR ');
                }
            }
        }

        return filters;
    }

    getNextVisitPeriodFilter() {
        if (NextVisitPeriod.ALL.key == this.selectedPeriod) {
            return;
        }
        console.log(this.nextVisitStartDate);
        const date1 = new Date(this.nextVisitStartDate);
        const date2 = new Date(this.nextVisitEndDate);
        if (this.nextVisitStartDate) {
            date1.setHours(0, 0, 0);
            console.log(date1);
        }

        if (this.nextVisitEndDate) {
            date2.setHours(23, 59, 59);
            console.log(date2);
        }


        const nextVisitStartDate = date1.getTime();
        const nextVisitEndDate = date2.getTime();
        if (!this.selectedPeriod) {
            const inSevenDays = DateUtil.addDays(new Date(), 7);
            return `nextVisitTimestamp >= ${DateUtil.todayFirstTimestampOfDay()} AND nextVisitTimestamp <= ${inSevenDays.getTime()}`;
        }

        if (this.selectedPeriod == 'CUSTOM' && (this.nextVisitStartDate || this.nextVisitEndDate)) {
            let query = '';
            if (this.nextVisitStartDate && this.nextVisitEndDate) {
                query = `nextVisitTimestamp >= ${nextVisitStartDate} AND nextVisitTimestamp <= ${nextVisitEndDate}`;
            } else if (this.nextVisitStartDate && !this.nextVisitEndDate) {
                query = `nextVisitTimestamp >= ${nextVisitStartDate}`;
            } else if (!this.nextVisitStartDate && this.nextVisitEndDate) {
                query = `nextVisitTimestamp <= ${nextVisitEndDate}`;
            }

            return query;
        }

        switch (this.selectedPeriod) {
            case NextVisitPeriod.NEXT_SEVEN_DAYS.key:
                const inSevenDays = DateUtil.addDays(new Date(), 7);
                return `NOT marker:healed AND nextVisitTimestamp >= ${DateUtil.todayFirstTimestampOfDay()} AND nextVisitTimestamp <= ${inSevenDays.getTime()}`;
            case NextVisitPeriod.NEXT_THIRTY_DAYS.key:
                const inThirtyDays = DateUtil.addDays(new Date(), 30);
                return `NOT marker:healed AND nextVisitTimestamp >= ${DateUtil.todayFirstTimestampOfDay()} AND nextVisitTimestamp <= ${inThirtyDays.getTime()}`;
            case NextVisitPeriod.LAST_SEVEN_DAYS.key:
                const lastSevenDays = DateUtil.minusDays(new Date(), 7);
                return `NOT marker:healed AND nextVisitTimestamp >= ${lastSevenDays.getTime()} AND nextVisitTimestamp <= ${DateUtil.nowTimestamp()}`;
            case NextVisitPeriod.TODAY.key:
                return `NOT marker:healed AND nextVisitTimestamp >= ${DateUtil.todayFirstTimestampOfDay()} AND nextVisitTimestamp <= ${DateUtil.todayLastTimestampOfDay()}`;
            case NextVisitPeriod.CURRENT_MONTH.key:
                const firstDayOfMonth = DateUtil.getFirstDayOfMonth();
                const lastDayOfMonth = DateUtil.getLastDayOfMonth();
                return `NOT marker:healed AND nextVisitTimestamp >= ${firstDayOfMonth.getTime()} AND nextVisitTimestamp <= ${lastDayOfMonth.getTime()}`;
            case NextVisitPeriod.CURRENT_YEAR.key:
                const firstDayOfYear = DateUtil.getFirstDayOfYear();
                const lastDayOfYear = DateUtil.getLastDayOfYear();
                return `NOT marker:healed AND nextVisitTimestamp >= ${firstDayOfYear.getTime()} AND nextVisitTimestamp <= ${lastDayOfYear.getTime()}`;
            case NextVisitPeriod.LAST_YEAR.key:
                const firstDayOfLastYear = DateUtil.getFirstDayOfLastYear();
                const lastDayOfLastYear = DateUtil.getLastDayOfLastYear();
                return `NOT marker:healed AND nextVisitTimestamp >= ${firstDayOfLastYear.getTime()} AND nextVisitTimestamp <= ${lastDayOfLastYear.getTime()}`;
            case NextVisitPeriod.ALL.key:
                return '';
        }
    }

    getSearchConfigExport() {
        const facetFilters = this.getFilters();
        return {
            filters: this.getMalnutritionFilters(),
            facetFilters: facetFilters,
            hitsPerPage: this.hitsPerPage * 100,
            page: this.currPage
        };
    }

    cancelFilterByPeriod() {
        this.selectedPeriod = NextVisitPeriod.NEXT_SEVEN_DAYS.key;
        this.customPeriodSearch = false;
        this.nextVisitStartDate = null;
        this.nextVisitEndDate = null;
        this.search(true);
    }

    filterByPeriod(event) {
        this.selectedPeriod = event;
        if (!this.customPeriodSearch && this.selectedPeriod == NextVisitPeriod.CUSTOM.key) {
            this.customPeriodSearch = true;
            return;
        }
        console.log(event);
        this.search(true);
    }

    async search(clear: boolean) {
        // this.childCaseService.indexAllCases();

        if (clear) {
            this.clearResults();
        }
        this.searching = true;
        const config = this.getSearchConfig();
        console.log(config);
        await this.getIndex().search(this.searchTerm, config).then(data => {
            this.searching = false;
            this.totalResults = data.nbHits;
            this.numberOfPages = data.nbPages;
            this.results = this.results.concat(data.hits);
        });
    }

    getTotalCases() {
        const facetFilters = [this.getBasicFilter()];
        this.indexByVisitDate.search('', {
            facetFilters: facetFilters,
            hitsPerPage: 0,
            attributesToRetrieve: [],
            attributesToHighlight: [],
            facets: []
        }).then(data => {
            this.totalCases = data.nbHits;
        });
    }

    setHitsPerPage(hitsPerPage) {
        this.hitsPerPage = hitsPerPage;
        this.search(true);
    }

    clearSearch() {
        this.searchTerm = '';
        this.search(true);
    }

    clearResults() {
        this.currPage = 0;
        this.results = [];
    }

    async findNext() {
        this.currPage = this.currPage + 1;
        this.search(false);
    }

    lastPageReached() {
        return ((this.currPage + 1) >= this.numberOfPages);
    }

    exportVisitsList() {
        this.exporting = true;
        this.convertVisitsToCsv().then(csvData => {
            this.exporting = false;
            let blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
            let dwldLink = document.createElement('a');
            let url = URL.createObjectURL(blob);
            let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
            if (isSafariBrowser) {
                dwldLink.setAttribute('target', '_blank');
            }
            dwldLink.setAttribute('href', url);
            dwldLink.setAttribute('download', 'ninos-visitas-' + DateUtil.nowFormated('YYYY-MM-DD') + '.csv');
            dwldLink.style.visibility = 'hidden';
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
        }).catch(ex => {
            this.exporting = false;
        });
    }

    async convertVisitsToCsv(): Promise<string> {
        const headers = [
            'Token del Caso',
            'MUAC',
            'Resultado',
            'Fecha',
            'Próxima Visita',
            'Hospital',
            'Alerta',
            'Semana',
            'User UID',
            'Usuario NOMBRE',
            'Usuario PROVINCIA',
            'Usuario MUNICIPIO',
            'Organizatión',
            'Orden de Visita',
            'Comentarios',
            'Instruciones',
            'Síntomas'
        ].join(';') + '\r\n';

        const visitResults = await this.algoliaService.getAllResults(
            this.indexCasesVisits,
            '',
            this.getCasesSearchConfigExport(),
            (progress: number) => {
                this.exportingProgress = Math.floor(progress * 100) / 100;
            }
        );

        const visitData = visitResults.flatMap(data => data.hits.map(value => ({...value})));
        const contentBuilder = new ChildrenCsvVisitsContentBuilder(visitData, this.symptomService, this.translocoService);
        const csvContent = contentBuilder.build();
        return headers + csvContent;
    }

    getCasesSearchConfigExport() {
        return {
            filters: '',
            facetFilters: [''],
            hitsPerPage: this.hitsPerPage * 100,
            attributesToRetrieve: [
                'caseId',
                'muac',
                'marker',
                'nextVisit',
                'fowardToHospital',
                'week',
                'province',
                'county',
                'organizationName',
                'gestationWeek',
                'date',
                'order',
                'healthAssistant',
                'comments',
                'instructions',
                'symptoms'
            ],
            page: 0
        };
    }

}
