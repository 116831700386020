export class Symptoms {

    // Edema -------------------------------------------------------------------------------------------------------------------------------
    public static EDEMA = {
        value: 'EDEMA',
        i18nkey: 'edema',
        selected: false
    };

    public static GENERALIZED_EDEMA = {
        value: 'GENERALIZED_EDEMA',
        i18nkey: 'generalizedEdema',
        selected: false
    };

    public static EDEMA_ON_BOTH_FEET = {
        value: 'EDEMA_ON_BOTH_FEET',
        i18nkey: 'edemaOnBothFeet',
        selected: false
    };
    // Complications -----------------------------------------------------------------------------------------------------------------------
    public static VOMITING = {
        value: 'VOMITING',
        i18nkey: 'vomiting',
        selected: false
    };

    public static DIARRHEA = {
        value: 'DIARRHEA',
        i18nkey: 'diarrhea',
        selected: false
    };

    public static FEVER = {
        value: 'FEVER',
        i18nkey: 'fever',
        selected: false
    };

    public static SOMNOLENCE = {
        value: 'SOMNOLENCE',
        i18nkey: 'somnolence',
        selected: false
    };
    // Appetite ----------------------------------------------------------------------------------------------------------------------------
    public static DOES_NOT_BRING_FOOD_TO_MOUTH = {
        value: 'DOES_NOT_BRING_FOOD_TO_MOUTH',
        i18nkey: 'doesNotBringFoodToMouth',
        selected: false
    };

    public static DONT_SUCK = {
        value: 'DONT_SUCK',
        i18nkey: 'dontSuck',
        selected: false
    };

    public static DONT_SWALLOW = {
        value: 'DONT_SWALLOW',
        i18nkey: 'dontSwallow',
        selected: false
    };

    // Deprecated ----------------------------------------------------------------------------------------------------------------------------
    public static ANOTHER_DISEASE = {
        value: 'ANOTHER_DISEASE',
        i18nkey: 'anotherDisease',
        selected: false
    };

    public static OTHER_COMPLICATIONS = {
        value: 'OTHER_COMPLICATIONS',
        i18nkey: 'otherComplications',
        selected: false
    };

    public static HAS_NO_APPETITE = {
        value: 'HAS_NO_APPETITE',
        i18nkey: 'hasNoAppetite',
        selected: false
    };

    public static getKey(value): string {
        return Symptoms.listSymptoms().filter(symptom => symptom.value === value).map(symptom => symptom.i18nkey)[0];
    }

    public static listSymptoms() {
        return [Symptoms.EDEMA, Symptoms.EDEMA_ON_BOTH_FEET, Symptoms.GENERALIZED_EDEMA,
            Symptoms.VOMITING, Symptoms.DIARRHEA, Symptoms.FEVER, Symptoms.SOMNOLENCE,
            Symptoms.ANOTHER_DISEASE,
            Symptoms.DOES_NOT_BRING_FOOD_TO_MOUTH, Symptoms.DONT_SUCK, Symptoms.DONT_SWALLOW];
    }

    public static edemas() {
        return [Symptoms.EDEMA_ON_BOTH_FEET, Symptoms.GENERALIZED_EDEMA];
    }

    public static complications() {
        return [Symptoms.VOMITING, Symptoms.DIARRHEA, Symptoms.FEVER, Symptoms.SOMNOLENCE];
    }

    public static appetite() {
        return [Symptoms.DOES_NOT_BRING_FOOD_TO_MOUTH, Symptoms.DONT_SUCK, Symptoms.DONT_SWALLOW];
    }

}
