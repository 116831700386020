export class CovidSymptoms {

    public static DRY_COUGH = {
        value: 'DRY_COUGH',
        i18nkey: 'dryCough',
        selected: false
    };

    public static DIFFICULTY_BREATHING = {
        value: 'DIFFICULTY_BREATHING',
        i18nkey: 'difficultyBreathing',
        selected: false
    };

    public static SORE_THROAT = {
        value: 'SORE_THROAT',
        i18nkey: 'soreThroat',
        selected: false
    };

    public static ANOSMIA = {
        value: 'ANOSMIA',
        i18nkey: 'anosmia',
        selected: false
    };

    public static AGEUSIA = {
        value: 'AGEUSIA',
        i18nkey: 'ageusia',
        selected: false
    };

    public static values() {
        return [CovidSymptoms.DRY_COUGH, CovidSymptoms.DIFFICULTY_BREATHING, CovidSymptoms.SORE_THROAT,
            CovidSymptoms.ANOSMIA, CovidSymptoms.AGEUSIA].map(obj => Object.assign({}, obj));
    }

    public static getKey(value) {
        return CovidSymptoms.values().filter(symptom => symptom.value === value).map(symptom => symptom.i18nkey);
    }

}
