export class WomenCsvContentBuilder {

    constructor(private data: any) {
    }

    build(): string {
        return this.data.map(woman => [
            `${woman.firstName} ${woman.lastName}`,
            this.formatDate(woman.birthDate),
            woman.muac,
            woman.currentMuac,
            woman.province,
            woman.county,
            woman.organizationName,
            woman.currentAssistantUid,
            this.translateMarker(woman?.marker),
            this.translateAlert(woman?.iconAlert?.class),
            this.translateBoolean(woman?.womanReferredToTheHospital),
            this.translateBoolean(woman?.transferredCase),
            this.formatDate(woman?.visitDate),
            this.translateType(woman.type),
            this.getFormattedHealthAssistantName(woman),
            woman?.healthAssistant?.province ?? '',
            woman?.healthAssistant?.county ?? '',
            woman.token,
            woman.address,
            woman.phone,
            woman.identityCard,
            this.sanitizeText(woman.chronicDisease)
        ].join(';')).join('\r\n');
    }

    private getFormattedHealthAssistantName(visit: any) {
        if (!visit || !visit.healthAssistant) {
            return '';
        }
        return `${visit.healthAssistant.firstName} ${visit.healthAssistant.lastName}`;
    }

    private translateMarker(marker: string) {
        if (!marker) {
            return '';
        }
        switch (marker) {
            case 'acute-malnutrition':
                return 'Desnutrición aguda';
            case 'outside-risk-zone':
                return 'Mujer fuera de la zona de riesgo';
            case 'healed':
                return 'Recuperado';
            case 'no-measured':
                return 'No medido';
        }
    }

    private translateAlert(alert: string) {
        if (!alert) {
            return '';
        }
        switch (alert) {
            case 'calendar-icon':
                return 'Tratamiento interrumpido';
            case 'exclamation-icon':
                return 'Sin progreso';
            case 'hospital-icon':
                return 'Hospital';
        }
    }

    private translateBoolean(boo: boolean) {
        if (boo === null || boo === undefined) {
            return '';
        }
        if (boo) {
            return 'Sí';
        }
        return 'No';
    }

    private translateType(type: string) {
        if (!type) {
            return '';
        }
        switch (type) {
            case 'woman.pregnant':
                return 'Embarazada';
            case 'woman.lactating':
                return 'Lactante';
        }
    }

    private formatDate(dateString: string): string {
        if (!dateString) {
            return '';
        }
        if (dateString.includes('T')) {
            const date = new Date(dateString);
            return date.toISOString().split('T')[0];
        }
        return dateString;
    }

    private removeHtmlTags(text: string): string {
        if (!text) {
            return text;
        }
        return text.replace(/<\/?[^>]+(>|$)/g, '');
    }

    private removeBreakLines(text: string): string {
        if (!text) {
            return text;
        }
        return text.replace(/[\r\n]/g, '');
    }

    private sanitizeText(text: string): string {
        if (!text) {
            return text;
        }
        text = this.removeHtmlTags(text);
        return this.removeBreakLines(text);
    }

}
