import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';
import {Router} from '@angular/router';
import {MuacUtil} from '../../util/muac.util';
import {WomanCaseModel} from '../../model/woman-case.model';
import {WomanCaseService} from '../../services/woman-case.service';
import {LoadingService} from '../../services/loading.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FilterUtil} from '../../util/filter.util';
import {WomenService} from '../../services/women.service';
import algoliasearch from 'algoliasearch';
import {AlgoliaUtil} from '../../util/algolia.util';
import {AlgoliaService} from '../../services/algolia.service';
import {LoginService} from '../../services/login.service';
import {ProvinceService} from '../../services/province.service';
import {CountyService} from '../../services/county.service';
import {FilterWomanService} from '../../services/filter-woman.service';
import {DateUtil} from '../../util/date.util';
import {User} from '../../model/user.model';
import {NextVisitPeriod} from '../../constants/next-visit-period';
import {UserService} from '../../services/user.service';
import {WomanCsvVisitsContentBuilder} from '../../builder/export/csv/woman-csv-visits-content-builder';
import {WomenCsvContentBuilder} from '../../builder/export/csv/women-csv-content-builder';

export declare interface Searchable {
    search(clear: boolean);

    getFilters(): Array<string>;
}

@Component({
    selector: 'app-women',
    templateUrl: './women.component.html',
    styleUrls: ['./women.component.scss']
})
export class WomenComponent implements OnInit, OnDestroy, Searchable {

    order = 0;
    orders = {0: 'Próxima visita', 1: 'Nombre', 2: 'MUAC'};

    indexByName;
    indexByVisitDate;
    indexByMuac;
    indexWomanVisits;

    searchTerm = '';
    searching = false;
    filterBy: FilterUtil;
    results: WomanCaseModel[];
    totalResults = 0;
    totalCases = 0;
    profileFilter = '';
    currPage = 0;
    numberOfPages = 0;
    hitsPerPage = 15;

    exporting = false;
    exportingProgress = 0;

    nextVisitPeriods = NextVisitPeriod.values();
    nextVisitStartDate: Date;
    nextVisitEndDate: Date;

    selectedPeriod: string;
    customPeriodSearch = false;

    @Input()
    public user: User;

    constructor(private algoliaService: AlgoliaService,
                private provinceService: ProvinceService,
                private countyService: CountyService,
                private loadingService: LoadingService,
                private womenService: WomenService,
                private womanService: WomanCaseService,
                private loginService: LoginService,
                private modalService: NgbModal,
                private filterWomanService: FilterWomanService,
                private router: Router,
                private userService: UserService,
                private translocoService: TranslocoService) {
        const client = algoliasearch(AlgoliaUtil.APP_ID, AlgoliaUtil.getSearchApiKey());
        this.indexByName = client.initIndex('woman_order_by_name');
        this.indexByMuac = client.initIndex('woman_order_by_muac');
        this.indexWomanVisits = client.initIndex('woman_visits');
        this.indexByVisitDate = client.initIndex(AlgoliaUtil.getWomenIndex());
        // this.womanService.getNextSevenDaysCases().then(result => {
        //     this.results = result.hits as unknown as WomanCaseModel[];
        // });
        this.selectedPeriod = NextVisitPeriod.NEXT_SEVEN_DAYS.key;
        // this.search(true);
        filterWomanService.setSearchable = this;
        // this.user = this.loginService.getCurrentUser();
    }

    ngOnInit(): void {
        this.algoliaService.getFilterByProfile(this.loginService.authUser).then(filter => {
            this.profileFilter = filter;
            this.getTotalCases();
            this.search(true);
        });
    }

    ngOnDestroy() {

    }

    setOrder(order) {
        this.order = parseInt(order);
        this.search(true);
    }

    isSupervisor() {
        return this.loginService.getCurrentUser().isSupervisor;
    }

    muac(value: number) {
        return MuacUtil.getWomanMuacClassification(value);
    }

    isLactating(womanCase: WomanCaseModel) {
        return this.womanService.isLactating(womanCase);
    }

    showWomanProfile(womanCase: WomanCaseModel) {
        this.router.navigate(['app/woman-profile/', womanCase.token]);
    }

    getIndex() {
        switch (this.order) {
            case 0:
                return this.indexByVisitDate;
            case 1:
                return this.indexByName;
            case 2:
                return this.indexByMuac;
            default:
                return this.indexByVisitDate;
        }
    }

    getBasicFilter() {
        if (this.user != null) {
            return `currentAssistantUid:${this.user.uid}`;
        } else {
            return this.profileFilter;
        }
    }

    getFilters(): Array<string> {
        let filters = new Array();

        filters = [this.getBasicFilter()];

        if (this.filterWomanService.provincesSelected.length > 0) {
            filters = [...filters, this.filterWomanService.provincesSelected.map(p => (`province:${p}`))];
        }

        if (this.filterWomanService.countysSelected.length > 0) {
            filters = [...filters, this.filterWomanService.countysSelected.map(p => (`county:${p}`))];
        }

        if (this.filterWomanService.organizationSelected.length > 0) {
            filters = [...filters, this.filterWomanService.organizationSelected.map(p => (`organizationName:${p}`))];
        }

        if (this.filterWomanService.filtersSelected.length > 0) {
            let filtersOrs = [];
            this.filterWomanService.filtersSelected.forEach(filterInt => {
                const filterObj = this.filterWomanService.filterOptions.find(f => f.id == filterInt);
                if (filterInt < 4) {
                    if (filterInt == 3) {
                        filtersOrs.push('transferredCase:true');
                    } else {
                        filtersOrs.push('visits.alertIcon.class:' + filterObj.class);
                    }
                } else {
                    filtersOrs.push('marker:' + filterObj.class);
                }
            });
            filters = [...filters, filtersOrs];
        }

        return filters;
    }

    exportList() {
        this.exporting = true;
        this.convertToCsv().then(csvData => {
            this.exporting = false;
            let blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
            let dwldLink = document.createElement('a');
            let url = URL.createObjectURL(blob);
            let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
            if (isSafariBrowser) {
                dwldLink.setAttribute('target', '_blank');
            }
            dwldLink.setAttribute('href', url);
            dwldLink.setAttribute('download', 'mujeres-' + DateUtil.nowFormated('YYYY-MM-DD') + '.csv');
            dwldLink.style.visibility = 'hidden';
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
        }).catch(ex => {
            this.exporting = false;
            console.error('exporting_error', ex);
        });
    }

    async convertToCsv(): Promise<String> {
        const headers = [
            'Mujer',
            'Fecha de Nascimento',
            'Muac - Primeira Visita',
            'Muac',
            'Provincia',
            'Municipio',
            'Organización',
            'User UID',
            'Nivel de Desnutrición',
            'Alerta',
            'Hospital',
            'Transferido',
            'Primeira Visita',
            'Tipo',
            'Usuario NOMBRE',
            'Usuario PROVINCIA',
            'Usuario MUNICIPIO',
            'Token del Caso',
            'Dirección',
            'Telefono',
            'Cedula',
            'Enfermidad Crónica'
        ].join(';') + '\r\n';

        const women = await this.algoliaService.getAllResults(
            this.getIndex(),
            this.searchTerm,
            this.getSearchConfigExport(),
            (progress) => {
                this.exportingProgress = Math.floor(progress * 100) / 100;
            }
        );

        const data = women.flatMap(data => data.hits.map(value => ({...value})));
        const contentBuilder = new WomenCsvContentBuilder(data);
        const csvContent = contentBuilder.build();
        return headers + csvContent;
    }

    exportVisitsList() {
        this.exporting = true;
        this.convertVisitsToCsv().then(csvData => {
            this.exporting = false;
            let blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
            let dwldLink = document.createElement('a');
            let url = URL.createObjectURL(blob);
            let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
            if (isSafariBrowser) {
                dwldLink.setAttribute('target', '_blank');
            }
            dwldLink.setAttribute('href', url);
            dwldLink.setAttribute('download', 'mujeres-visitas-' + DateUtil.nowFormated('YYYY-MM-DD') + '.csv');
            dwldLink.style.visibility = 'hidden';
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
        }).catch(ex => {
            this.exporting = false;
        });
    }

    async convertVisitsToCsv(): Promise<string> {
        const headers = [
            'Token del Caso',
            'MUAC',
            'Resultado',
            'Próxima Visita',
            'Hospital',
            'Semana',
            'Usuario UID',
            'Usuario NOMBRE',
            'Usuario PROVINCIA',
            'Usuario MUNICIPIO',
            'Organizatión',
            'Semana de Gestación',
            'Fecha',
            'Orden de Visita',
            'Comentarios',
            'Instruciones',
            'Síntomas'
        ].join(';') + '\r\n';

        const visitResults = await this.algoliaService.getAllResults(
            this.indexWomanVisits,
            '',
            this.getCasesSearchConfigExport(),
            (progress: number) => {
                this.exportingProgress = Math.floor(progress * 100) / 100;
            }
        );

        const visitData = visitResults.flatMap(data => data.hits.map(value => ({ ...value })));
        const contentBuilder = new WomanCsvVisitsContentBuilder(visitData, this.translocoService);
        const csvContent = contentBuilder.build();
        return headers + csvContent;
    }

    getSearchConfigExport() {
        const facetFilters = this.getFilters();
        return {
            filters: this.getMalnutritionFilters(),
            facetFilters: facetFilters,
            hitsPerPage: this.hitsPerPage * 100,
            page: 0
        };
    }

    getMalnutritionFilters() {
        return this.filterWomanService.onlyMalnutritionCases ? 'visits.marker:acute-malnutrition' : '';
    }

    getCasesSearchConfigExport() {
        return {
            filters: '',
            facetFilters: [''],
            hitsPerPage: this.hitsPerPage * 100,
            attributesToRetrieve: [
                'caseId',
                'muac',
                'marker',
                'nextVisit',
                'fowardToHospital',
                'week',
                'province',
                'county',
                'organizationName',
                'gestationWeek',
                'date',
                'order',
                'healthAssistant',
                'comments',
                'instructions',
                'symptoms'
            ],
            page: 0
        };
    }

    async search(clear: boolean) {
        if (clear) {
            this.clearResults();
        }
        this.searching = true;
        const config = this.getSearchConfig();
        await this.getIndex().search(this.searchTerm, config).then(data => {
            this.searching = false;
            this.totalResults = data.nbHits;
            this.numberOfPages = data.nbPages;
            this.results = this.results.concat(data.hits);
        });
    }

    getSearchConfig() {
        const facetFilters = this.getFilters();
        let filters = this.getMalnutritionFilters();
        if (filters.length == 0) {
            filters = this.getNextVisitPeriodFilter();
        } else if (NextVisitPeriod.ALL.key != this.selectedPeriod) {
            filters = filters.concat(' AND ' + this.getNextVisitPeriodFilter());
        }

        return {
            filters: filters,
            facetFilters: facetFilters,
            hitsPerPage: this.hitsPerPage,
            page: this.currPage
        };
    }

    getNextVisitPeriodFilter() {
        if (NextVisitPeriod.ALL.key == this.selectedPeriod) {
            return;
        }
        const date1 = new Date(this.nextVisitStartDate);
        const date2 = new Date(this.nextVisitEndDate);
        if (this.nextVisitStartDate) {
            date1.setHours(0, 0, 0);
        }

        if (this.nextVisitEndDate) {
            date2.setHours(23, 59, 59);
        }


        const nextVisitStartDate = date1.getTime();
        const nextVisitEndDate = date2.getTime();
        if (!this.selectedPeriod) {
            const inSevenDays = DateUtil.addDays(new Date(), 7);
            return `nextVisitTimestamp >= ${DateUtil.todayFirstTimestampOfDay()} AND nextVisitTimestamp <= ${inSevenDays.getTime()}`;
        }

        if (this.selectedPeriod == 'CUSTOM' && (this.nextVisitStartDate || this.nextVisitEndDate)) {
            let query = '';
            if (this.nextVisitStartDate && this.nextVisitEndDate) {
                query = `nextVisitTimestamp >= ${nextVisitStartDate} AND nextVisitTimestamp <= ${nextVisitEndDate}`;
            } else if (this.nextVisitStartDate && !this.nextVisitEndDate) {
                query = `nextVisitTimestamp >= ${nextVisitStartDate}`;
            } else if (!this.nextVisitStartDate && this.nextVisitEndDate) {
                query = `nextVisitTimestamp <= ${nextVisitEndDate}`;
            }

            return query;
        }

        switch (this.selectedPeriod) {
            case NextVisitPeriod.NEXT_SEVEN_DAYS.key:
                const inSevenDays = DateUtil.addDays(new Date(), 7);
                return `NOT marker:healed AND nextVisitTimestamp >= ${DateUtil.nowTimestamp()} AND nextVisitTimestamp <= ${inSevenDays.getTime()}`;
            case NextVisitPeriod.NEXT_THIRTY_DAYS.key:
                const inThirtyDays = DateUtil.addDays(new Date(), 30);
                return `NOT marker:healed AND nextVisitTimestamp >= ${DateUtil.todayFirstTimestampOfDay()} AND nextVisitTimestamp <= ${inThirtyDays.getTime()}`;
            case NextVisitPeriod.LAST_SEVEN_DAYS.key:
                const lastSevenDays = DateUtil.minusDays(new Date(), 7);
                return `NOT marker:healed AND nextVisitTimestamp >= ${lastSevenDays.getTime()} AND nextVisitTimestamp <= ${DateUtil.nowTimestamp()}`;
            case NextVisitPeriod.TODAY.key:
                return `NOT marker:healed AND nextVisitTimestamp >= ${DateUtil.todayFirstTimestampOfDay()} AND nextVisitTimestamp <= ${DateUtil.todayLastTimestampOfDay()}`;
            case NextVisitPeriod.CURRENT_MONTH.key:
                const firstDayOfMonth = DateUtil.getFirstDayOfMonth();
                const lastDayOfMonth = DateUtil.getLastDayOfMonth();
                return `NOT marker:healed AND nextVisitTimestamp >= ${firstDayOfMonth.getTime()} AND nextVisitTimestamp <= ${lastDayOfMonth.getTime()}`;
            case NextVisitPeriod.CURRENT_YEAR.key:
                const firstDayOfYear = DateUtil.getFirstDayOfYear();
                const lastDayOfYear = DateUtil.getLastDayOfYear();
                return `NOT marker:healed AND nextVisitTimestamp >= ${firstDayOfYear.getTime()} AND nextVisitTimestamp <= ${lastDayOfYear.getTime()}`;
            case NextVisitPeriod.LAST_YEAR.key:
                const firstDayOfLastYear = DateUtil.getFirstDayOfLastYear();
                const lastDayOfLastYear = DateUtil.getLastDayOfLastYear();
                return `NOT marker:healed AND nextVisitTimestamp >= ${firstDayOfLastYear.getTime()} AND nextVisitTimestamp <= ${lastDayOfLastYear.getTime()}`;
            case NextVisitPeriod.ALL.key:
                return '';
        }
    }

    getTotalCases() {
        const facetFilters = [this.getBasicFilter()];
        this.indexByVisitDate.search('', {
            facetFilters: facetFilters,
            hitsPerPage: 0,
            attributesToRetrieve: [],
            attributesToHighlight: [],
            facets: []
        }).then(data => {
            this.totalCases = data.nbHits;
        });
    }

    setHitsPerPage(hitsPerPage) {
        this.hitsPerPage = hitsPerPage;
        this.search(true);
    }

    clearSearch() {
        this.searchTerm = '';
        this.search(true);
    }

    clearResults() {
        this.currPage = 0;
        this.results = [];
    }

    async findNext() {
        this.currPage = this.currPage + 1;
        this.search(false);
    }

    lastPageReached() {
        return ((this.currPage + 1) >= this.numberOfPages);
    }


    filterByPeriod(event) {
        this.selectedPeriod = event;
        if (!this.customPeriodSearch && this.selectedPeriod == NextVisitPeriod.CUSTOM.key) {
            this.customPeriodSearch = true;
            return;
        }
        this.search(true);
    }

    cancelFilterByPeriod() {
        this.selectedPeriod = NextVisitPeriod.NEXT_SEVEN_DAYS.key;
        this.customPeriodSearch = false;
        this.nextVisitStartDate = null;
        this.nextVisitEndDate = null;
        this.search(true);
    }

}
