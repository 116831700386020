export class ComplicationsSymptoms {
    // Complications during pregnancy ------------------------------------------------------------------------------------------------------
    public static ARTERIAL_HYPERTENSION = {
        value: 'ARTERIAL_HYPERTENSION',
        i18nkey: 'arterialHypertension',
        selected: false
    };

    public static GESTATION_DIABETES = {
        value: 'GESTATION_DIABETES',
        i18nkey: 'gestationDiabetes',
        selected: false
    };

    public static ANEMIA = {
        value: 'ANEMIA',
        i18nkey: 'anemia',
        selected: false
    };

    public static SEXUALLY_TRANSMITTED_INFECTION = {
        value: 'SEXUALLY_TRANSMITTED_INFECTION',
        i18nkey: 'sexuallyTransmittedInfection',
        selected: false
    };

    public static URINARY_INFECTION = {
        value: 'URINARY_INFECTION',
        i18nkey: 'urinaryInfection',
        selected: false
    };

    public static VAGINAL_INFECTION = {
        value: 'VAGINAL_INFECTION',
        i18nkey: 'vaginalInfection',
        selected: false
    };

    // Obstetric Danger --------------------------------------------------------------------------------------------------------------------

    public static FREQUENT_VOMITING = {
        value: 'FREQUENT_VOMITING',
        i18nkey: 'frequentVomiting',
        selected: false
    };

    public static PAINFUL_CONTRACTIONS = {
        value: 'PAINFUL_CONTRACTIONS',
        i18nkey: 'painfulContractions',
        selected: false
    };

    public static UTERINE_HEIGHT_NOT_INCREASE = {
        value: 'UTERINE_HEIGHT_NOT_INCREASE',
        i18nkey: 'uterineNotIncrease',
        selected: false
    };


    public static pregnancyComplications() {
        return [ComplicationsSymptoms.ARTERIAL_HYPERTENSION,
            ComplicationsSymptoms.GESTATION_DIABETES,
            ComplicationsSymptoms.SEXUALLY_TRANSMITTED_INFECTION,
            ComplicationsSymptoms.ANEMIA,
            ComplicationsSymptoms.URINARY_INFECTION,
            ComplicationsSymptoms.VAGINAL_INFECTION].map(obj => Object.assign({}, obj));
    }

    public static obstetricDanger() {
        return [ComplicationsSymptoms.FREQUENT_VOMITING,
            ComplicationsSymptoms.PAINFUL_CONTRACTIONS,
            ComplicationsSymptoms.UTERINE_HEIGHT_NOT_INCREASE].map(obj => Object.assign({}, obj));
    }

    public static getKey(value) {
        return ComplicationsSymptoms.listSymptoms().filter(symptom => symptom.value === value).map(symptom => symptom.i18nkey);
    }

    public static listSymptoms() {
        return [ComplicationsSymptoms.ARTERIAL_HYPERTENSION,
            ComplicationsSymptoms.GESTATION_DIABETES,
            ComplicationsSymptoms.SEXUALLY_TRANSMITTED_INFECTION,
            ComplicationsSymptoms.ANEMIA,
            ComplicationsSymptoms.URINARY_INFECTION,
            ComplicationsSymptoms.VAGINAL_INFECTION,
            ComplicationsSymptoms.FREQUENT_VOMITING,
            ComplicationsSymptoms.PAINFUL_CONTRACTIONS,
            ComplicationsSymptoms.UTERINE_HEIGHT_NOT_INCREASE];
    }

}
