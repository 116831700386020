import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ChildCaseModel} from '../../model/child-case.model';
import {TranslocoService} from '@ngneat/transloco';
import {MuacUtil} from '../../util/muac.util';
import {Router} from '@angular/router';
import algoliasearch from 'algoliasearch';
import {AlgoliaUtil} from '../../util/algolia.util';
import {LoadingService} from '../../services/loading.service';
import {LoginService} from '../../services/login.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlgoliaService} from '../../services/algolia.service';
import {ChildCaseService} from '../../services/child-case.service';
import {ProvinceService} from '../../services/province.service';
import {CountyService} from '../../services/county.service';
import {FilterChildService} from '../../services/filter-child.service';
import {DateUtil} from '../../util/date.util';
import {User} from '../../model/user.model';
import {MalnutritionLevelUtil} from '../../util/malnutrition-level.util';
import {NextVisitPeriod} from '../../constants/next-visit-period';
import {NumberUtil} from '../../util/number.util';
import {AngularFirestore} from '@angular/fire/firestore';

export declare interface Searchable {
    search(clear: boolean);

    getFilters(): Array<string>;

}

@Component({
    selector: 'app-children',
    templateUrl: './children.component.html',
    styleUrls: ['./children.component.scss']
})
export class ChildrenComponent implements OnInit, OnDestroy, Searchable {

    order = 0;
    orders = {0: 'Próxima visita', 1: 'Nombre', 2: 'MUAC'};

    indexByName;
    indexByVisitDate;
    indexByMuac;
    indexCasesVisits;

    index;
    searchTerm = '';
    results: ChildCaseModel[] = new Array();
    searching = false;
    totalResults = 0;
    totalCases = 0;
    offlineImg = '../../assets/icon/icon-offline.svg';
    profileFilter = '';
    currPage = 0;
    numberOfPages = 0;
    hitsPerPage = 15;

    exporting = false;
    exportingProgress = 0;
    nextVisitPeriods = NextVisitPeriod.values();
    nextVisitStartDate: Date;
    nextVisitEndDate: Date;

    selectedPeriod: string;

    @Input()
    public user: User;
    customPeriodSearch = false;

    constructor(private algoliaService: AlgoliaService,
                private provinceService: ProvinceService,
                private countyService: CountyService,
                private loadingService: LoadingService,
                private loginService: LoginService,
                private modalService: NgbModal,
                private childCaseService: ChildCaseService,
                private filterChildService: FilterChildService,
                private router: Router,
                private db: AngularFirestore,
                private translocoService: TranslocoService) {
        const client = algoliasearch(AlgoliaUtil.APP_ID, AlgoliaUtil.getSearchApiKey());
        this.indexByName = client.initIndex('cases_order_by_name');
        this.indexByMuac = client.initIndex('cases_muac');
        this.indexCasesVisits = client.initIndex('cases_visits');
        this.indexByVisitDate = client.initIndex(AlgoliaUtil.getChildrenIndex());
        this.childCaseService.getNextSevenDaysCases().then(result => {
            console.log(result);
        });
        filterChildService.setSearchable = this;
    }

    ngOnInit(): void {
        this.algoliaService.getFilterByProfile(this.loginService.authUser).then(filter => {
            this.profileFilter = filter;
            this.getTotalCases();
            this.search(true);
        });
    }

    ngOnDestroy() {
    }

    isSupervisor() {
        return this.loginService.getCurrentUser().isSupervisor;
    }

    setOrder(order) {
        this.order = parseInt(order);
        this.search(true);
    }

    muac(value: number) {
        return MuacUtil.getMuacClassification(value);
    }

    showChildProfile(childCase: ChildCaseModel) {
        this.router.navigate(['app/child-profile/', childCase.token]);
    }

    getIndex() {
        switch (this.order) {
            case 0:
                return this.indexByVisitDate;
            case 1:
                return this.indexByName;
            case 2:
                return this.indexByMuac;
            default:
                return this.indexByVisitDate;
        }
    }

    getBasicFilter() {
        let basicFilter = '';
        if (this.user != null) {
            basicFilter = 'assistantUid:' + this.user.uid;
        } else {
            basicFilter = this.profileFilter;
        }

        return basicFilter;
    }

    getFilters(): Array<string> {
        let filters = new Array();

        filters = [this.getBasicFilter()];

        if (this.filterChildService.provincesSelected.length > 0) {
            filters = [...filters, this.filterChildService.provincesSelected.map(p => (`province:${p}`))];
        }

        if (this.filterChildService.countysSelected.length > 0) {
            filters = [...filters, this.filterChildService.countysSelected.map(p => (`county:${p}`))];
        }

        if (this.filterChildService.organizationSelected.length > 0) {
            filters = [...filters, this.filterChildService.organizationSelected.map(p => (`organizationName:${p}`))];
        }

        if (this.filterChildService.filtersSelected.length > 0) {
            let filtersOrs = [];
            this.filterChildService.filtersSelected.forEach(filterInt => {
                const filterObj = this.filterChildService.filterOptions.find(f => f.id == filterInt);
                if (filterInt < 4) {
                    if (filterInt == 3) {
                        filtersOrs.push('transferredCase:true');
                    } else {
                        filtersOrs.push('alert.class:' + filterObj.class);
                    }
                } else {
                    filtersOrs.push('marker:' + filterObj.class);
                }
            });
            filters = [...filters, filtersOrs];
        }

        return filters;
    }

    exportList() {
        this.exporting = true;
        this.convertToCsv().then(csvData => {
            this.exporting = false;
            const blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
            const dwldLink = document.createElement('a');
            const url = URL.createObjectURL(blob);
            const isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
            if (isSafariBrowser) {
                dwldLink.setAttribute('target', '_blank');
            }
            dwldLink.setAttribute('href', url);
            dwldLink.setAttribute('download', 'ninos-' + DateUtil.nowFormated('YYYY-MM-DD') + '.csv');
            dwldLink.style.visibility = 'hidden';
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
        }).catch(ex => {
            this.exporting = false;
        });
    }

    convertToCsv(): Promise<String> {
        const ref = this;
        return new Promise((resolve, reject) => {
            try {
                this.translocoService
                    .selectTranslateObject('children.export.titles')
                    .subscribe(async (titles) => {
                        let csvContent = `${titles}\r\n`;
                        this.algoliaService.getAllResults(this.getIndex(), this.searchTerm, this.getSearchConfigExport(), (progress) => {
                            ref.exportingProgress = Math.floor(progress * 100) / 100;
                        }).then(searchResults => {
                            const children = searchResults.reduce((acc, dt) => (acc.concat(dt.hits.map(v => ({...v})))), []);
                            children.forEach(child => csvContent += this.buildNewCSVLine(csvContent, child));
                            resolve(csvContent);
                        });
                    });
            } catch (ex) {
                reject(ex);
            }
        });
    }

    buildNewCSVLine(csvLine, child) {
        const firstVisitInfo = this.getFirstVisitInfoForExportData(child);
        let ageInYears = 0;
        let ageInMonths = 0;
        let birthDateFormatted = '';
        if (child.childBirthDateTimestamp) {
            ageInYears = DateUtil.yearsDiff(new Date(child.childBirthDateTimestamp), new Date());
            ageInMonths = DateUtil.monthsDiff(new Date(child.childBirthDateTimestamp), new Date());
            birthDateFormatted = DateUtil.format(child.childBirthDateTimestamp, 'DD/MM/YYYY');
        }
        let gender = 'undefined';
        if (child.gender) {
            gender = child.gender.toLowerCase();
        }
        const markerTranslated = this.translateTextCSVColumn(
            `children.export.desnutritionLevel.${child?.marker}`, child?.marker
        );
        const firstVisitInfoMarkerTranslated = this.translateTextCSVColumn(
            `children.export.desnutritionLevel.${firstVisitInfo?.marker}`, firstVisitInfo.marker
        );
        const alertTranslated = this.translateTextCSVColumn(
            `children.export.alert.${child?.alert?.class}`, child?.alert?.class
        );
        const genderTranslated = this.translateTextCSVColumn(
            `children.export.gender.${gender}`, gender
        );
        const childReferredToTheHospitalTranslated = this.translateBooleanCSVColumn(child?.childReferredToTheHospital);
        const transferredCaseTranslated = this.translateBooleanCSVColumn(child.transferredCase);
        return [
            child.childName,
            child.province,
            child.county,
            child.organizationName,
            child.assistantUid,
            birthDateFormatted,
            ageInYears,
            ageInMonths,
            genderTranslated,
            NumberUtil.toFixed(firstVisitInfo.muacNumber, 1),
            NumberUtil.toFixed(child.muac, 1),
            firstVisitInfoMarkerTranslated,
            markerTranslated,
            firstVisitInfo.date,
            alertTranslated,
            childReferredToTheHospitalTranslated,
            transferredCaseTranslated,
            child.token,
            `${child?.healthAssistants[0]?.firstName ?? ''} ${child?.healthAssistants[0]?.lastName ?? ''}`,
            child?.healthAssistants[0]?.province ?? '',
            child?.healthAssistants[0]?.county ?? '',
            child.childAddress,
            child.mothersPhone
        ].join(';').concat('\r\n');
    }

    translateTextCSVColumn(translationKey, dataToTranslate) {
        if (!dataToTranslate) {
            return '';
        }
        return this.translocoService.translateObject(translationKey);
    }

    translateBooleanCSVColumn(dataToTranslate) {
        if (dataToTranslate) {
            return this.translocoService.translateObject('yes');
        }
        return this.translocoService.translateObject('no');
    }

    getFirstVisitInfoForExportData(child) {
        let muacNumber = 0;
        let marker = '';
        let date = '';
        if (!child || !child.visits || !child.visits[0]) {
            return {muacNumber, marker, date};
        }
        const firstVisist = child.visits[0];
        if (firstVisist.muacNumber) {
            muacNumber = firstVisist.muacNumber;
        }
        if (firstVisist.marker) {
            marker = firstVisist.marker;
        }
        if (firstVisist.date) {
            date = DateUtil.format(firstVisist.date, 'DD/MM/YYYY');
        }
        return {muacNumber, marker, date};
    }

    getSearchConfig() {
        const facetFilters = this.getFilters();
        let filters = this.getMalnutritionFilters();
        if (filters.length == 0) {
            filters = this.getNextVisitPeriodFilter();
        } else if (NextVisitPeriod.ALL.key != this.selectedPeriod) {
            filters = filters.concat(' AND ' + this.getNextVisitPeriodFilter());
        }

        return {
            filters: filters,
            facetFilters: facetFilters,
            hitsPerPage: this.hitsPerPage,
            page: this.currPage
        };
    }

    getMalnutritionFilters() {
        let filters = '';
        if (this.filterChildService.onlyMalnutritionCases) {
            const levels = MalnutritionLevelUtil.manutritionLevels().map(level => level.description);
            for (let i = 0; i < levels.length; i++) {
                filters = filters.concat(`visits.marker:${levels[i]}`);
                if (i < (levels.length - 1)) {
                    filters = filters.concat(' OR ');
                }
            }
        }

        return filters;
    }

    getNextVisitPeriodFilter() {
        if (NextVisitPeriod.ALL.key == this.selectedPeriod) {
            return;
        }
        console.log(this.nextVisitStartDate);
        const date1 = new Date(this.nextVisitStartDate);
        const date2 = new Date(this.nextVisitEndDate);
        if (this.nextVisitStartDate) {
            date1.setHours(0, 0, 0);
            console.log(date1);
        }

        if (this.nextVisitEndDate) {
            date2.setHours(23, 59, 59);
            console.log(date2);
        }


        const nextVisitStartDate = date1.getTime();
        const nextVisitEndDate = date2.getTime();
        if (!this.selectedPeriod) {
            const inSevenDays = DateUtil.addDays(new Date(), 7);
            return `nextVisitTimestamp >= ${DateUtil.todayFirstTimestampOfDay()} AND nextVisitTimestamp <= ${inSevenDays.getTime()}`;
        }

        if (this.selectedPeriod == 'CUSTOM' && (this.nextVisitStartDate || this.nextVisitEndDate)) {
            let query = '';
            if (this.nextVisitStartDate && this.nextVisitEndDate) {
                query = `nextVisitTimestamp >= ${nextVisitStartDate} AND nextVisitTimestamp <= ${nextVisitEndDate}`;
            } else if (this.nextVisitStartDate && !this.nextVisitEndDate) {
                query = `nextVisitTimestamp >= ${nextVisitStartDate}`;
            } else if (!this.nextVisitStartDate && this.nextVisitEndDate) {
                query = `nextVisitTimestamp <= ${nextVisitEndDate}`;
            }

            return query;
        }

        switch (this.selectedPeriod) {
            case NextVisitPeriod.NEXT_SEVEN_DAYS.key:
                const inSevenDays = DateUtil.addDays(new Date(), 7);
                return `NOT marker:healed AND nextVisitTimestamp >= ${DateUtil.todayFirstTimestampOfDay()} AND nextVisitTimestamp <= ${inSevenDays.getTime()}`;
            case NextVisitPeriod.NEXT_THIRTY_DAYS.key:
                const inThirtyDays = DateUtil.addDays(new Date(), 30);
                return `NOT marker:healed AND nextVisitTimestamp >= ${DateUtil.todayFirstTimestampOfDay()} AND nextVisitTimestamp <= ${inThirtyDays.getTime()}`;
            case NextVisitPeriod.LAST_SEVEN_DAYS.key:
                const lastSevenDays = DateUtil.minusDays(new Date(), 7);
                return `NOT marker:healed AND nextVisitTimestamp >= ${lastSevenDays.getTime()} AND nextVisitTimestamp <= ${DateUtil.nowTimestamp()}`;
            case NextVisitPeriod.TODAY.key:
                return `NOT marker:healed AND nextVisitTimestamp >= ${DateUtil.todayFirstTimestampOfDay()} AND nextVisitTimestamp <= ${DateUtil.todayLastTimestampOfDay()}`;
            case NextVisitPeriod.CURRENT_MONTH.key:
                const firstDayOfMonth = DateUtil.getFirstDayOfMonth();
                const lastDayOfMonth = DateUtil.getLastDayOfMonth();
                return `NOT marker:healed AND nextVisitTimestamp >= ${firstDayOfMonth.getTime()} AND nextVisitTimestamp <= ${lastDayOfMonth.getTime()}`;
            case NextVisitPeriod.CURRENT_YEAR.key:
                const firstDayOfYear = DateUtil.getFirstDayOfYear();
                const lastDayOfYear = DateUtil.getLastDayOfYear();
                return `NOT marker:healed AND nextVisitTimestamp >= ${firstDayOfYear.getTime()} AND nextVisitTimestamp <= ${lastDayOfYear.getTime()}`;
            case NextVisitPeriod.LAST_YEAR.key:
                const firstDayOfLastYear = DateUtil.getFirstDayOfLastYear();
                const lastDayOfLastYear = DateUtil.getLastDayOfLastYear();
                return `NOT marker:healed AND nextVisitTimestamp >= ${firstDayOfLastYear.getTime()} AND nextVisitTimestamp <= ${lastDayOfLastYear.getTime()}`;
            case NextVisitPeriod.ALL.key:
                return '';
        }
    }

    getSearchConfigExport() {
        const facetFilters = this.getFilters();
        return {
            filters: this.getMalnutritionFilters(),
            facetFilters: facetFilters,
            hitsPerPage: this.hitsPerPage * 100,
            page: this.currPage
        };
    }

    cancelFilterByPeriod() {
        this.selectedPeriod = NextVisitPeriod.NEXT_SEVEN_DAYS.key;
        this.customPeriodSearch = false;
        this.nextVisitStartDate = null;
        this.nextVisitEndDate = null;
        this.search(true);
    }

    filterByPeriod(event) {
        this.selectedPeriod = event;
        if (!this.customPeriodSearch && this.selectedPeriod == NextVisitPeriod.CUSTOM.key) {
            this.customPeriodSearch = true;
            return;
        }
        console.log(event);
        this.search(true);
    }

    async search(clear: boolean) {
        // this.childCaseService.indexAllCases();

        if (clear) {
            this.clearResults();
        }
        this.searching = true;
        const config = this.getSearchConfig();
        console.log(config);
        await this.getIndex().search(this.searchTerm, config).then(data => {
            this.searching = false;
            this.totalResults = data.nbHits;
            this.numberOfPages = data.nbPages;
            this.results = this.results.concat(data.hits);
        });
    }

    getTotalCases() {
        const facetFilters = [this.getBasicFilter()];
        this.indexByVisitDate.search('', {
            facetFilters: facetFilters,
            hitsPerPage: 0,
            attributesToRetrieve: [],
            attributesToHighlight: [],
            facets: []
        }).then(data => {
            this.totalCases = data.nbHits;
        });
    }

    setHitsPerPage(hitsPerPage) {
        this.hitsPerPage = hitsPerPage;
        this.search(true);
    }

    clearSearch() {
        this.searchTerm = '';
        this.search(true);
    }

    clearResults() {
        this.currPage = 0;
        this.results = [];
    }

    async findNext() {
        this.currPage = this.currPage + 1;
        this.search(false);
    }

    lastPageReached() {
        return ((this.currPage + 1) >= this.numberOfPages);
    }

    exportVisitsList() {
        this.exporting = true;
        this.convertVisitsToCsv().then(csvData => {
            this.exporting = false;
            let blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
            let dwldLink = document.createElement('a');
            let url = URL.createObjectURL(blob);
            let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
            if (isSafariBrowser) {
                dwldLink.setAttribute('target', '_blank');
            }
            dwldLink.setAttribute('href', url);
            dwldLink.setAttribute('download', 'ninos-visitas' + DateUtil.nowFormated('YYYY-MM-DD') + '.csv');
            dwldLink.style.visibility = 'hidden';
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
        }).catch(ex => {
            this.exporting = false;
        });
    }

    async convertVisitsToCsv(): Promise<string> {
        const headers = [
            'ID del Caso',
            'MUAC',
            'Marcador',
            'Próxima Visita',
            'Derivado al Hospital',
            'Semana',
            'Organización',
            'Provincia',
            'Municipio',
            'Semana de Gestación',
            'Fecha',
            'Orden',
            'Comentarios'
        ].join(';') + '\r\n';

        const visitResults = await this.algoliaService.getAllResults(
            this.indexCasesVisits,
            '',
            this.getCasesSearchConfigExport(),
            (progress: number) => {
                this.exportingProgress = Math.floor(progress * 100) / 100;
            }
        );

        const visitData = visitResults.flatMap(data => data.hits.map(value => ({ ...value })));

        const csvContent = visitData.map(visit => [
            visit.caseId ?? '',
            visit.muac ?? '',
            this.translateMarker(visit.marker),
            visit.nextVisit ?? '',
            this.translateBoolean(visit.fowardToHospital),
            visit.week ?? '',
            visit.province ?? '',
            visit.county ?? '',
            visit.organizationName ?? '',
            visit.gestationWeek ?? '',
            visit.date ?? '',
            visit.order ?? '',
            visit.comments ?? ''
        ].join(';')).join('\r\n');

        return headers + csvContent;
    }

    private translateMarker(marker: string) {
        if (!marker) {
            return '';
        }
        switch (marker) {
            case 'acute-malnutrition':
                return 'Desnutrición aguda';
            case 'outside-risk-zone':
                return 'Mujer fuera de la zona de riesgo';
            case 'healed':
                return 'Recuperada';
            case 'no-measured':
                return 'No medido';
        }
    }

    private translateBoolean(boo: boolean) {
        if (boo === null || boo === undefined) {
            return '';
        }
        if (boo) {
            return 'Sí';
        }
        return 'No';
    }

    getCasesSearchConfigExport() {
        return {
            filters: '',
            facetFilters: [''],
            hitsPerPage: this.hitsPerPage * 100,
            attributesToRetrieve: [
                'caseId',
                'muac',
                'marker',
                'nextVisit',
                'fowardToHospital',
                'week',
                'province',
                'county',
                'organizationName',
                'gestationWeek',
                'date',
                'order',
                'comments'
            ],
            page: 0
        };
    }

}
