import {TranslocoService} from '@ngneat/transloco';
import {DateUtil} from '../../../util/date.util';
import {NumberUtil} from '../../../util/number.util';

export class ChildrenCsvContentBuilder {

    constructor(private data: any,
                private translocoService: TranslocoService) {
    }

    build(): string {
        return this.data.map(child => this.buildChild(child));
    }

    private buildChild(child: any): string {
        const firstVisitInfo = this.getFirstVisitInfoForExportData(child);
        let ageInYears = 0;
        let ageInMonths = 0;
        let birthDateFormatted = '';
        if (child.childBirthDateTimestamp) {
            ageInYears = DateUtil.yearsDiff(new Date(child.childBirthDateTimestamp), new Date());
            ageInMonths = DateUtil.monthsDiff(new Date(child.childBirthDateTimestamp), new Date());
            birthDateFormatted = DateUtil.format(child.childBirthDateTimestamp, 'DD/MM/YYYY');
        }
        let gender = '';
        if (child.gender) {
            gender = child.gender.toLowerCase();
        }
        const markerTranslated = this.translateTextCSVColumn(
            `children.export.desnutritionLevel.${child?.marker}`, child?.marker
        );
        const firstVisitInfoMarkerTranslated = this.translateTextCSVColumn(
            `children.export.desnutritionLevel.${firstVisitInfo?.marker}`, firstVisitInfo.marker
        );
        const alertTranslated = this.translateTextCSVColumn(
            `children.export.alert.${child?.alert?.class}`, child?.alert?.class
        );
        const genderTranslated = this.translateTextCSVColumn(
            `children.export.gender.${gender}`, gender
        );
        const childReferredToTheHospitalTranslated = this.translateBooleanCSVColumn(child?.childReferredToTheHospital);
        const transferredCaseTranslated = this.translateBooleanCSVColumn(child.transferredCase);
        return [
            child.childName,
            child.province,
            child.county,
            child.organizationName,
            child.assistantUid,
            birthDateFormatted,
            ageInYears,
            ageInMonths,
            genderTranslated,
            NumberUtil.toFixed(firstVisitInfo.muacNumber, 1),
            NumberUtil.toFixed(child.muac, 1),
            firstVisitInfoMarkerTranslated,
            markerTranslated,
            firstVisitInfo.date,
            child.nextVisit,
            alertTranslated,
            childReferredToTheHospitalTranslated,
            transferredCaseTranslated,
            child.token,
            `${child?.healthAssistants[0]?.firstName ?? ''} ${child?.healthAssistants[0]?.lastName ?? ''}`,
            child?.healthAssistants[0]?.province ?? '',
            child?.healthAssistants[0]?.county ?? '',
            child.childAddress,
            child.mothersName,
            child.mothersPhone,
            this.sanitizeText(child.chronicDisease),
            child.caregiverName,
            child.caregiverPhone
        ].join(';').concat('\r\n');
    }

    translateTextCSVColumn(translationKey, dataToTranslate) {
        if (!dataToTranslate) {
            return '';
        }
        return this.translocoService.translateObject(translationKey);
    }

    translateBooleanCSVColumn(dataToTranslate) {
        if (dataToTranslate) {
            return this.translocoService.translateObject('yes');
        }
        return this.translocoService.translateObject('no');
    }

    getFirstVisitInfoForExportData(child) {
        let muacNumber = 0;
        let marker = '';
        let date = '';
        if (!child || !child.visits || !child.visits[0]) {
            return {muacNumber, marker, date};
        }
        const firstVisist = child.visits[0];
        if (firstVisist.muacNumber) {
            muacNumber = firstVisist.muacNumber;
        }
        if (firstVisist.marker) {
            marker = firstVisist.marker;
        }
        if (firstVisist.date) {
            date = DateUtil.format(firstVisist.date, 'DD/MM/YYYY');
        }
        return {muacNumber, marker, date};
    }

    private removeHtmlTags(text: string): string {
        if (!text) {
            return text;
        }
        return text.replace(/<\/?[^>]+(>|$)/g, '');
    }

    private removeBreakLines(text: string): string {
        if (!text) {
            return text;
        }
        return text.replace(/[\r\n]/g, '');
    }

    private sanitizeText(text: string): string {
        if (!text) {
            return text;
        }
        text = this.removeHtmlTags(text);
        return this.removeBreakLines(text);
    }

}
