import {VisitModel} from '../../../model/visit.model';
import {CovidSymptoms} from '../../../constants/covid-symptoms';
import {SymptomModel} from '../../../model/symptom.model';
import {ComplicationsSymptoms} from '../../../constants/complications-symptoms';
import {LactatingComplicationsSymptoms} from '../../../constants/lactating-complications-symptoms';
import {TranslocoService} from '@ngneat/transloco';

export class WomanCsvVisitsContentBuilder {

    constructor(private visitData: any, private translocoService: TranslocoService) {
    }

    build(): string {
        return this.visitData.map(visit => [
            visit.caseId ?? '',
            visit.muac ?? '',
            this.translateMarker(visit.marker),
            visit.nextVisit ?? '',
            this.translateBoolean(visit.fowardToHospital),
            visit.week ?? '',
            visit.healthAssistant ? visit.healthAssistant.uid : '',
            this.getFormattedHealthAssistantName(visit),
            visit.province ?? '',
            visit.county ?? '',
            visit.organizationName ?? '',
            visit.gestationWeek ?? '',
            this.formatDate(visit.date),
            visit.order ?? '',
            visit.comments ?? '',
            this.getFormattedInstructions(visit.instructions),
            this.getFormattedSymptoms(visit)
        ].join(';')).join('\r\n');
    }

    private getCovidSymptoms(visit: VisitModel): string[] {
        const covidSymptoms = CovidSymptoms.values().map(covidSymptom => new SymptomModel(covidSymptom));
        return covidSymptoms.filter(symptom => visit.symptoms.includes(symptom.value)).map(foundSymptom => 'addWoman.covidSymptoms.' + foundSymptom.i18nValue);
    }

    private getObstetricComplications(visit: VisitModel): string[] {
        const obstetricComplications = ComplicationsSymptoms.obstetricDanger().map(symptom => new SymptomModel(symptom));
        return obstetricComplications.filter(symptom => visit.symptoms.includes(symptom.value)).map(foundSymptom => 'addWoman.complicationsSymptoms.' + foundSymptom.i18nValue);
    }

    private getPregnancyComplications(visit: VisitModel): string[] {
        const pregnancyComplications = ComplicationsSymptoms.pregnancyComplications().map(symptom => new SymptomModel(symptom));
        return pregnancyComplications.filter(symptom => visit.symptoms.includes(symptom.value)).map(foundSymptom => 'addWoman.complicationsSymptoms.' + foundSymptom.i18nValue);
    }

    private getPuerperiumDangers(visit: VisitModel): string[] {
        const puerperiumDangerSings = LactatingComplicationsSymptoms.dangerSignsInPuerperium().map(symptom => new SymptomModel(symptom));
        return puerperiumDangerSings.filter(symptom => visit.symptoms.includes(symptom.value)).map(foundSymptom => 'addWoman.lactatingSymptoms.' + foundSymptom.i18nValue);
    }

    private getPostpartumDangers(visit: VisitModel): string[] {
        const postpartumDangerSings = LactatingComplicationsSymptoms.postpartumDangerSigns().map(symptom => new SymptomModel(symptom));
        return postpartumDangerSings.filter(symptom => visit.symptoms.includes(symptom.value)).map(foundSymptom => 'addWoman.lactatingSymptoms.' + foundSymptom.i18nValue);
    }

    private getFormattedSymptoms(visit: VisitModel): string {
        if (!visit.symptoms) {
            return '';
        }
        const allSymptomsI18nKeys = this.mergeUniqueArrays(
            this.getCovidSymptoms(visit),
            this.getObstetricComplications(visit),
            this.getPregnancyComplications(visit),
            this.getPuerperiumDangers(visit),
            this.getPostpartumDangers(visit)
        );
        if (!allSymptomsI18nKeys) {
            return '';
        }
        const uniqueTranslations = Array.from(
            new Set(allSymptomsI18nKeys.map(symptomI18nKey =>
                this.removeHtmlTags(this.translocoService.translate(symptomI18nKey))
            ))
        );
        return uniqueTranslations.join('/');
    }

    private mergeUniqueArrays(...arrays: string[][]): string[] {
        return Array.from(new Set(arrays.flat().filter(item => item.length > 0)));
    }

    private removeHtmlTags(text: string): string {
        return text.replace(/<\/?[^>]+(>|$)/g, "");
    }

    private getFormattedInstructions(instructions: Array<string>) {
        if (!instructions || instructions.length === 0) {
            return '';
        }
        return instructions.map(instruction => this.removeHtmlTags(instruction)).join('/');
    }

    private getFormattedHealthAssistantName(visit: any) {
        if (!visit || !visit.healthAssistant) {
            return '';
        }
        return `${visit.healthAssistant.firstName} ${visit.healthAssistant.lastName}`;
    }

    private translateMarker(marker: string) {
        if (!marker) {
            return '';
        }
        switch (marker) {
            case 'acute-malnutrition':
                return 'Desnutrición aguda';
            case 'outside-risk-zone':
                return 'Fuera de la zona de riesgo';
            case 'healed':
                return 'Recuperada';
            case 'no-measured':
                return 'No medido';
        }
    }

    private translateBoolean(boo: boolean) {
        if (boo === null || boo === undefined) {
            return '';
        }
        if (boo) {
            return 'Sí';
        }
        return 'No';
    }

    private formatDate(dateString: string): string {
        if (!dateString) {
            return '';
        }
        if (dateString.includes('T')) {
            const date = new Date(dateString);
            return date.toISOString().split('T')[0];
        }
        return dateString;
    }
}
