export class SymptomModel {

    i18nValue: string;
    value: string;
    isChecked: boolean;

    constructor(symptoms?: any) {
        if (symptoms != null) {
            this.isChecked = symptoms.selected;
            this.value = symptoms.value;
            this.i18nValue = symptoms.i18nkey;
        }
    }
}
