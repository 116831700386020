import {TranslocoService} from '@ngneat/transloco';
import {SymptomsService} from '../../../services/symptoms.service';

export class ChildrenCsvVisitsContentBuilder {

    constructor(private visitData: any,
                private symptomService: SymptomsService,
                private translocoService: TranslocoService) {
    }

    build(): string {
        return this.visitData.map(visit => [
            visit.caseId ?? '',
            visit.muac ?? '',
            this.translateMarker(visit.marker),
            this.formatDate(visit.date) ?? '',
            visit.nextVisit ?? '',
            this.translateBoolean(visit.fowardToHospital),
            visit.alert,
            visit.week ?? '',
            visit.healthAssistant ? visit.healthAssistant.uid : '',
            this.getFormattedHealthAssistantName(visit),
            visit.province ?? '',
            visit.county ?? '',
            visit.organizationName ?? '',
            visit.order ?? '',
            this.sanitizeText(visit.comments) ?? '',
            this.getFormattedInstructions(visit.instructions),
            this.getFormattedSymptoms(visit.symptoms)
        ].join(';')).join('\r\n');
    }

    private removeHtmlTags(text: string): string {
        if (!text) {
            return text;
        }
        return text.replace(/<\/?[^>]+(>|$)/g, '');
    }

    private removeBreakLines(text: string): string {
        if (!text) {
            return text;
        }
        return text.replace(/[\r\n]/g, '');
    }

    private sanitizeText(text: string): string {
        if (!text) {
            return text;
        }
        text = this.removeHtmlTags(text);
        return this.removeBreakLines(text);
    }

    private getFormattedInstructions(instructions: Array<string>) {
        if (!instructions || instructions.length === 0) {
            return '';
        }
        return instructions.map(instruction => this.sanitizeText(instruction)).join('/');
    }

    private getFormattedHealthAssistantName(visit: any) {
        if (!visit || !visit.healthAssistant) {
            return '';
        }
        return `${visit.healthAssistant.firstName} ${visit.healthAssistant.lastName}`;
    }

    private translateMarker(marker: string) {
        if (!marker) {
            return '';
        }
        switch (marker) {
            case 'acute-malnutrition':
                return 'Desnutrición aguda';
            case 'outside-risk-zone':
                return 'Mujer fuera de la zona de riesgo';
            case 'healed':
                return 'Recuperado';
            case 'no-measured':
                return 'No medido';
        }
    }

    private translateBoolean(boo: boolean) {
        if (boo === null || boo === undefined) {
            return '';
        }
        if (boo) {
            return 'Sí';
        }
        return 'No';
    }

    private getFormattedSymptoms(symptoms: string[]) {
        if (!symptoms) {
            return;
        }
        return symptoms.filter(key => !!key && key.trim().length > 0).map(key =>
            this.sanitizeText(
                this.translocoService.translate(
                    this.symptomService.getByKey(key)
                )
            )
        );
    }

    private formatDate(dateString: string): string {
        if (!dateString) {
            return '';
        }
        if (dateString.includes('T')) {
            const date = new Date(dateString);
            return date.toISOString().split('T')[0];
        }
        return dateString;
    }

}
