import {Injectable} from '@angular/core';
import {Symptoms} from '../constants/symptoms';

@Injectable({
    providedIn: 'root'
})
export class SymptomsService {

    constructor() {
    }

    public getByKey(symptom: string) {
        const symptomKey = Symptoms.listSymptoms().filter(key => key.value == symptom)[0];
        if (symptomKey) {
            return 'addChild.symptoms.' + symptomKey.i18nkey;
        }
    }

}


