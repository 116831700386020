export class LactatingComplicationsSymptoms {

    public static ANEMIA = {
        value: 'ANEMIA',
        i18nkey: 'anemia',
        selected: false
    };

    public static VAGINAL_BLEEDING = {
        value: 'VAGINAL_BLEEDING',
        i18nkey: 'vaginalBleeding',
        selected: false
    };

    public static INFECTIONS = {
        value: 'INFECTIONS',
        i18nkey: 'infections',
        selected: false
    };

    public static BREAST_PAIN = {
        value: 'BREAST_PAIN',
        i18nkey: 'breastPain',
        selected: false
    };

    public static PAIN = {
        value: 'PAIN',
        i18nkey: 'pain',
        selected: false
    };

    public static POSTPARTUM_DEPRESSION = {
        value: 'POSTPARTUM_DEPRESSION',
        i18nkey: 'postpartumDepression',
        selected: false
    };

    public static CONSTIPATION = {
        value: 'CONSTIPATION',
        i18nkey: 'constipation',
        selected: false
    };

    // -------- Postpartum danger signs

    public static FEVER = {
        value: 'FEVER',
        i18nkey: 'fever',
        selected: false
    };

    public static HEADACHE = {
        value: 'HEADACHE',
        i18nkey: 'headache',
        selected: false
    };

    public static INSOMNIA = {
        value: 'INSOMNIA',
        i18nkey: 'insomnia',
        selected: false
    };

    public static LOSS_OF_VISION = {
        value: 'LOSS_OF_VISION',
        i18nkey: 'lossOfVision',
        selected: false
    };

    public static FETID_LOCHIA = {
        value: 'FETID_LOCHIA',
        i18nkey: 'fetidLochia',
        selected: false
    };


    public static dangerSignsInPuerperium() {
        return [LactatingComplicationsSymptoms.ANEMIA,
            LactatingComplicationsSymptoms.VAGINAL_BLEEDING,
            LactatingComplicationsSymptoms.INFECTIONS,
            LactatingComplicationsSymptoms.BREAST_PAIN,
            LactatingComplicationsSymptoms.PAIN,
            LactatingComplicationsSymptoms.POSTPARTUM_DEPRESSION,
            LactatingComplicationsSymptoms.CONSTIPATION].map(obj => Object.assign({}, obj));
    }

    public static postpartumDangerSigns() {
        return [LactatingComplicationsSymptoms.FEVER,
            LactatingComplicationsSymptoms.HEADACHE,
            LactatingComplicationsSymptoms.INSOMNIA,
            LactatingComplicationsSymptoms.LOSS_OF_VISION,
            LactatingComplicationsSymptoms.FETID_LOCHIA].map(obj => Object.assign({}, obj));
    }


}
